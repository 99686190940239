import React from 'react';
import Header from "../components/Header";

const Investments = () => {
  return (
      <div>
          <Header previousPage={"/"} text={"Инвестиции"} />
      </div>
  )
};

export default Investments;