const config = {
    development: {
        API_URL: 'http://localhost:8000',
    },
    production: {
        API_URL: 'https://selfmoney.home.sergeysidorenko.com',
    },
};

export const getConfig = () => {
    const env = process.env.NODE_ENV || 'development';
    return config[env];
};
