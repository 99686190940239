import React, {useEffect, useState} from 'react';
import {refreshToken} from "../services/authService";
import {StartsEndsCurrentMonth} from '../utils/Date'
import Loader from "./Loader";
import { getConfig } from '../config';


const Panel = () => {
    const [dataExpenses, setDataExpenses] = useState([]);
    const [dataIncomes, setDataIncomes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, endDate] = StartsEndsCurrentMonth();
    const { API_URL } = getConfig();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseExpenses = await fetch(
                    `${API_URL}/api/v1/expenses?startDate=${startDate}&endDate=${endDate}&category=all`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseExpenses.status === 401) {
                    refreshToken()
                }
                if (!responseExpenses.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const resultExpenses = await responseExpenses.json();

                const responseIncomes = await fetch(
                    `${API_URL}/api/v1/incomes?startDate=${startDate}&endDate=${endDate}&category=all`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseExpenses.status === 401) {
                    refreshToken()
                }
                if (!responseIncomes.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const resultIncomes = await responseIncomes.json();

                setDataExpenses(resultExpenses.expenses);
                setDataIncomes(resultIncomes.incomes);

            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate, API_URL]);

    if (loading) return <Loader/>
    if (error) return <p>Ошибка: {error}</p>;

    return (
        <div className={"Panel"}>
            <div className={"PanelTable"}>
                <div className={"PanelTableTitle"}>расходы</div>
                <div className={"PanelTableTitle"}>доходы</div>
            </div>
            <div className={"Tables"}>
                <table className={"PanelTable"}>
                    <tbody>
                    {dataExpenses.map(entry => (
                        <tr key={entry.id}>
                            <td>{entry.title.toLowerCase()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table className={"PanelTable"}>
                    <tbody>
                    {dataExpenses.map(entry => (
                        <tr key={entry.id}>
                            <td>{entry.amount}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table className={"PanelTable"}>
                    <tbody>
                    {dataIncomes.map(entry => (
                        <tr key={entry.id}>
                            <td>{entry.title.toLowerCase()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table className={"PanelTable"}>
                    <tbody>
                    {dataIncomes.map(entry => (
                        <tr key={entry.id}>
                            <td>{entry.amount}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Panel;