import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import ButtonTitle from "../components/ButtonTitle";
import SubmitButton from "../components/SubmitButton";
import ExpensesCategories from "../components/ExpensesCategories";

const ExpensesHistory = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        startDate: '',
        endDate: '',
        category: 'all',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Здесь вы можете обработать отправку формы, например, отправить данные на сервер
        console.log('Отправленные данные:', formData);

        // Сброс полей после отправки:
        // setFormData({startDate: '', endDate: '', category: ''});
        navigate('/expenses/history/list', { state: { formData } });
    };

    return (
        <div>
            <Header previousPage={"/expenses"} text={"Расходы"}/>
            <form onSubmit={handleSubmit}>
                <label htmlFor="startDate">
                    <ButtonTitle text={"С"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className={"FormInputText"}
                        value={formData.startDate}
                        onChange={handleChange}
                        required
                    />
                </div>
                <label htmlFor="endDate">
                    <ButtonTitle text={"ПО"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className={"FormInputText"}
                        value={formData.endDate}
                        onChange={handleChange}
                        required
                    />
                </div>
                <label htmlFor="category">
                    <ButtonTitle text={"КАТЕГОРИЯ"}/>
                </label>
                <div className={"FormInput"}>
                    <select
                        id="category"
                        name="category"
                        className={"FormInputText"}
                        value={formData.category}
                        onChange={handleChange}
                    >
                        <option key="all" value="all">все</option>
                        <ExpensesCategories renderType={"select"}/>
                    </select>
                </div>
                <SubmitButton text={"показать"}/>
            </form>
        </div>
    )
};

export default ExpensesHistory;