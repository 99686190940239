import React from 'react';

const SubmitButton = ({text, route}) => {
    return (
        <div>
            <button className={"SubmitButton"} type="submit">
                {text}
            </button>
        </div>
    );
};

export default SubmitButton;