import {getConfig} from "../config";

export const login = async (credentials) => {
    const {API_URL} = getConfig();

    // Логика запроса для получения токенов
    const response = await fetch(`${API_URL}/api/token/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
    });

    const data = await response.json();

    if (response.ok) {
        if (data.access) {
            localStorage.setItem('token', data.access);
            localStorage.setItem('refresh', data.refresh);
        } else {
            // Обработка ошибок
        }
        // Если используете куки, рефреш-токен может быть автоматически установлен
        return data;
    }
    throw new Error('Ошибка во время логина');
};

export const refreshToken = async () => {
    const {API_URL} = getConfig();

    try {
        const response = await fetch(`${API_URL}/api/token/refresh/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({"refresh": localStorage.getItem('refresh')}),
            // credentials: 'include'
        });

        if (response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh');
        }

        if (response.ok) {
            const data = await response.json();
            if (data.access) {
                localStorage.setItem('token', data.access);
                localStorage.setItem('refresh', data.refresh);
            } else {
                console.error('Ошибка обновления токена: ', data);
            }
            return data.access;
        }
    } catch (error) {
        console.error('Ошибка обновления токена:', error);
        // Дополнительная обработка ошибок
    }
    return null;
};