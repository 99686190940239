import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import ButtonTitle from "../components/ButtonTitle";
import SubmitButton from "../components/SubmitButton";
import {useAuth} from "../components/AuthProvider";
import Loader from "../components/Loader";
import {login as loginRequest} from "../services/authService";


const Login = () => {
    const navigate = useNavigate();
    const {isAuthenticated, login, loading} = useAuth();

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Отправить данные на сервер
        try {
            const data = await loginRequest(formData);
            // Успешная логика логина. Перенаправить пользователя или выполнить другие действия
            if (data) {
                login(data.access)
                // Сброс полей после отправки:
                setFormData({username: '', password: ''});
                navigate('/', {state: {formData}});
            }
        } catch (error) {
            console.error('Ошибка логина:', error);
        }
    };

    if (isAuthenticated) {
        navigate('/');
    }

    if (loading) {
        return <Loader/>;
    }

    return (
        <div>
            <header>
                <div className="Login-header">
                    <div className="App-header-title">
                        <p>Вход</p>
                    </div>
                </div>
            </header>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">
                    <ButtonTitle text={"логин"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        className={"FormInputText"}
                        value={formData.username}
                        onChange={handleChange}
                        placeholder="username"
                        required
                    />
                </div>
                <label htmlFor="password">
                    <ButtonTitle text={"пароль"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        className={"FormInputText"}
                        value={formData.password}
                        onChange={handleChange}
                        placeholder="password"
                        required
                    />
                </div>
                <SubmitButton text={"войти"}/>
            </form>
        </div>
    )
};

export default Login;