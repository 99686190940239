import React, {useState} from 'react';
import Header from "../components/Header";
import ButtonTitle from "../components/ButtonTitle";
import SubmitButton from "../components/SubmitButton";
import {default as ExpCat} from "../components/ExpensesCategories";
import {refreshToken} from "../services/authService";
import {getConfig} from "../config";

const ExpensesCategories = () => {
    const {API_URL} = getConfig();
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        category_title: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Здесь вы можете обработать отправку формы, например, отправить данные на сервер
        console.log('Отправленные данные:', formData);

        try {
            const response = await fetch(`${API_URL}/api/v1/expenses/categories`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(formData),
            });

            if (response.status === 401) {
                refreshToken()
            }
            if (!response.ok) {
                throw new Error('Ошибка получения данных');
            }
            const result = await response.json();
            if (result.success === true) {
                // Сброс полей после отправки:
                setFormData({category_title: ''});
            }

        } catch (error) {
            setError(error.message);
        }
    };

    if (error) return <p>Ошибка: {error}</p>;

    return (
        <div>
            <Header previousPage={"/expenses"} text={"Категории расходов"}/>
            <form onSubmit={handleSubmit}>
                <label htmlFor="category_title">
                    <ButtonTitle text={"ДОБАВИТЬ"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="text"
                        id="category_title"
                        name="category_title"
                        className={"FormInputText"}
                        value={formData.category_title}
                        onChange={handleChange}
                        placeholder={"новое название"}
                        required
                    />
                </div>
                <SubmitButton text={"добавить"}/>
            </form>
            <ButtonTitle text={"СУЩЕСТВУЮЩИЕ"}/>
            <ExpCat renderType={"button"}/>
        </div>
    )
};

export default ExpensesCategories;