import React from 'react';
import Header from "../components/Header";
import ButtonTitle from "../components/ButtonTitle";
import Button from "../components/Button";

const Incomes = () => {
  return (
      <div>
          <Header previousPage={"/"} text={"Доходы"} />
          <ButtonTitle text={"ДЕЙСТВИЕ"} />
          <Button text={"новый"} route={"/incomes/add"}/>
          <ButtonTitle text={"ПО ДАТЕ"} />
          <Button text={"история"} route={"/incomes/history"}/>
          <ButtonTitle text={"ПРОСМОТРЕТЬ"} />
          <Button text={"категории"} route={"/incomes/categories"}/>
      </div>
  )
};

export default Incomes;