import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from './AuthProvider';
import Loader from "./Loader"; // Импортируйте созданный провайдер

const PrivateRoute = () => {
    const {isAuthenticated, loading} = useAuth();

    if (loading) {
        // Пока идет проверка аутентификации, можно отображать индикатор загрузки или пустое состояние
        return (
            <Loader />
        )
    }

    return isAuthenticated ? <Outlet/> : <Navigate to="/login"/>;
};

export default PrivateRoute;