import React from 'react';
import Header from "../components/Header";

const Settings = () => {
  return (
      <div>
          <Header previousPage={"/"} text={"Настройки"} />
      </div>
  )
};

export default Settings;