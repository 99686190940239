import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Header from "../components/Header";
import {refreshToken} from "../services/authService";
import Loader from "../components/Loader";
import { getConfig } from '../config';

function formatDate(isoString) {
    // Создаем объект Date из строки даты
    const date = new Date(isoString);

    // Получаем компоненты даты
    const day = String(date.getUTCDate()).padStart(2, '0'); // день с ведущим нулем
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // месяцы в JS с нуля, поэтому прибавляем 1
    const year = date.getUTCFullYear(); // год

    // Форматируем дату как дд.мм.гггг
    return `${day}.${month}.${year}`;
}


const IncomesHistoryList = () => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const {formData} = location.state || {}; // Получение данных из состояния навигации
    const { API_URL } = getConfig();

    useEffect(() => {
        const fetchData = async () => {
            if (!formData) {
                setError('Нет данных для запроса');
                setLoading(false);
            }
            try {
                const response = await fetch(
                    `${API_URL}/api/v1/incomes/history?startDate=${formData.startDate}&endDate=${formData.endDate}&category=${formData.category}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (response.status === 401) {
                    refreshToken()
                }
                if (!response.ok) {
                    throw new Error('Ошибка получения данных');
                }

                const result = await response.json();

                setHistory(result.entries);
                setLoading(false);

            } catch (error) {
                setError(error.message);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [formData, API_URL]);

    if (loading) {
        return <Loader/>;
    }

    if (error) {
        return <p>Error loading data: {error.message}</p>;
    }

    return (
        <div>
            <Header previousPage={"/incomes/history"} text={"Доходы"}/>
            <div className={"Tables"}>
                <table>
                    <tbody>
                    {history.map(entry => (
                        <tr key={entry.id}>
                            <td>{formatDate(entry.datetime)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table>
                    <tbody>
                    {history.map(entry => (
                        <tr key={entry.id}>
                            <td>{entry.category.title.toLowerCase()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <table>
                    <tbody>
                    {history.map(entry => (
                        <tr key={entry.id}>
                            <td>{entry.amount}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default IncomesHistoryList;