import React from 'react';
import {useNavigate} from 'react-router-dom';

const ExtendButton = ({text, route, extendRoute, key, value}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    const handleClickExtend = () => {
        navigate(route + "/add");
    };

    return (
        <div className={"Button-extended"}>
            <button className={"Button-extended-add"} onClick={handleClick}>
            </button>
            <button key={key} value={value} onClick={handleClick}>
                {text}
            </button>
            <button className={"Button-extended-add"} onClick={handleClickExtend}>
                <img src={`/plus-add-math.svg`} alt={`plus-add-math`}/>
            </button>
        </div>
    );
};

export default ExtendButton;