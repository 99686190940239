import React, {useState} from 'react';
import Header from "../components/Header";
import ButtonTitle from "../components/ButtonTitle";
import IncomesCategories from "../components/IncomesCategories";
import SubmitButton from "../components/SubmitButton";
import {refreshToken} from "../services/authService";
import {getConfig} from '../config';


const IncomesAdd = () => {
    const {API_URL} = getConfig();
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        date: '',
        category: '',
        amount: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Здесь вы можете обработать отправку формы, например, отправить данные на сервер
        console.log('Отправленные данные:', formData);

        try {
            const response = await fetch(`${API_URL}/api/v1/incomes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(formData),
            });

            if (response.status === 401) {
                refreshToken()
            }
            if (!response.ok) {
                throw new Error('Ошибка получения данных');
            }
            const result = await response.json();
            if (result.success === true) {
                // Сброс полей после отправки:
                setFormData({date: '', category: '', amount: ''});
            }
        } catch (error) {
            setError(error.message);
        }
    };

    if (error) return <p>Ошибка: {error}</p>;

    return (
        <div>
            <Header previousPage={"/incomes"} text={"Доходы"}/>
            <form onSubmit={handleSubmit}>
                <label htmlFor="category">
                    <ButtonTitle text={"КАТЕГОРИЯ"}/>
                </label>
                <div className={"FormInput"}>
                    <select
                        id="category"
                        name="category"
                        className={"FormInputText"}
                        value={formData.category.id}
                        onChange={handleChange}
                        required
                    >
                        <option>выбрать категорию</option>
                        <IncomesCategories renderType={"select"}/>
                    </select>
                </div>
                <label htmlFor="date">
                    <ButtonTitle text={"ДАТА"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        className={"FormInputText"}
                        value={formData.date}
                        onChange={handleChange}
                        required
                    />
                </div>
                <label htmlFor="amount">
                    <ButtonTitle text={"СУММА"}/>
                </label>
                <div className={"FormInput"}>
                    <input
                        type="number"
                        id="amount"
                        name="amount"
                        className={"FormInputText"}
                        value={formData.amount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <SubmitButton text={"добавить"}/>
            </form>
        </div>
    )
};

export default IncomesAdd;