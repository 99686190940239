import React from 'react';
import Button from "../components/Button";
import ExtendButton from "../components/ExtendButton"
import Header from "../components/Header";

const Home = () => {
    return (
        <div>
            <Header/>
            <Button text="Дашборд" route="/dashboard"/>
            <ExtendButton text="Доходы" route="/incomes" extendRoute="/incomes/add"/>
            <ExtendButton text="Расходы" route="/expenses"/>
            <Button text="Инвестиции" route="/investments"/>
        </div>
    )
};

export default Home;