import { useNavigate } from 'react-router-dom';

const NavButton = ({ icon, route }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route);
  };

  return (
    <div className="NavButton" onClick={handleClick}>
      <img src={`/${icon}.svg`} alt={`${icon}`}/>
    </div>
  );
};

export default NavButton;