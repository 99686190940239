import React, {useState, useEffect} from 'react';
import Loader from '../components/Loader'
import {refreshToken} from "../services/authService";
import {StartsEndsCurrentMonth} from "../utils/Date";
import {getConfig} from "../config";
import Button from "./Button";


function IncomesCategories({renderType}) {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, endDate] = StartsEndsCurrentMonth();
    const {API_URL} = getConfig();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${API_URL}/api/v1/incomes/categories`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (response.status === 401) {
                    refreshToken()
                }
                if (!response.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const result = await response.json();
                setCategories(result.categories);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate, API_URL]);

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <p>Error loading data: {error.message}</p>;
    }

    if (renderType === 'select') {
        return (
            categories.map(category => (
                <option key={category.id} value={category.id}>{category.title}</option>
            ))
        )
    }

    if (renderType === 'button') {
        if (categories.length !== 0) {
            return (
                categories.map(category => (
                    <Button text={category.title} key={category.id} value={category.id}/>
                ))
            )
        }
        return (
            <Button text={"категории не найдены"}/>
        )
    }
}

export default IncomesCategories;