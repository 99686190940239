import React from 'react';
import Header from "../components/Header";
import ButtonTitle from "../components/ButtonTitle";
import Panel from "../components/Panel";
import BalancePieChart from "../components/ExpensesIncomesPieChart";

const Dashboard = () => {
  return (
      <div>
          <Header previousPage={"/"} text={"Дашборд"} />
          <ButtonTitle text={"ЛИЧНЫЕ ФИНАНСЫ"} />
          <Panel/>
          <BalancePieChart/>
      </div>
  )
};

export default Dashboard;