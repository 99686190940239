import React from 'react';
import Header from "../components/Header";
import Button from "../components/Button";
import ButtonTitle from "../components/ButtonTitle";

const Expenses = () => {
  return (
      <div>
          <Header previousPage={"/"} text={"Расходы"} />
          <ButtonTitle text={"ДЕЙСТВИЕ"} />
          <Button text={"новый"} route={"/expenses/add"}/>
          <ButtonTitle text={"ПО ДАТЕ"} />
          <Button text={"история"} route={"/expenses/history"}/>
          <ButtonTitle text={"ПРОСМОТРЕТЬ"} />
          <Button text={"категории"} route={"/expenses/categories"}/>
      </div>
  )
};

export default Expenses;