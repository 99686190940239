import './index.css';
import './App.css';
import './fonts/montserrat.css'
import './fonts/sf-pro-display.css'

import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';

import {AuthProvider} from './components/AuthProvider';
import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import Dashboard from "./pages/Dashboard";
import Incomes from "./pages/Incomes";
import Expenses from "./pages/Expenses";
import Investments from "./pages/Investments";
import Settings from "./pages/Settings";
import ExpensesHistory from "./pages/ExpensesHistory";
import ExpensesHistoryList from "./pages/ExpensesHistoryList";
import IncomesHistory from "./pages/IncomesHistory"
import IncomesHistoryList from "./pages/IncomesHistoryList"
import ExpensesAdd from "./pages/ExpensesAdd";
import IncomesAdd from "./pages/IncomesAdd";
import Login from "./pages/Login";
import ExpensesCategories from "./pages/ExpensesCategories";
import IncomesCategories from "./pages/IncomesCategories";

export default function App() {
    const location = useLocation();

    // useEffect для обновления фона при изменении маршрута
    useEffect(() => {
        const updateBackground = (path) => {
            switch (path) {
                case '/login':
                    document.body.style.backgroundImage = "url('/login-background.jpg')";
                    break;
                default:
                    document.body.style.backgroundImage = "url('/screen_bg.jpg')";
                    break;
            }
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundPosition = 'center';
            document.body.style.backgroundRepeat = 'no-repeat';
        };

        updateBackground(location.pathname);
    }, [location]);

    return (
        <div className="App">
            <main>
                <AuthProvider>
                    <Routes>
                        <Route element={<PrivateRoute/>}>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/incomes" element={<Incomes/>}/>
                            <Route path="/incomes/add" element={<IncomesAdd/>}/>
                            <Route path="/incomes/history" element={<IncomesHistory/>}/>
                            <Route path="/incomes/history/list" element={<IncomesHistoryList/>}/>
                            <Route path="/incomes/categories" element={<IncomesCategories/>}/>
                            <Route path="/expenses" element={<Expenses/>}/>
                            <Route path="/expenses/add" element={<ExpensesAdd/>}/>
                            <Route path="/expenses/history" element={<ExpensesHistory/>}/>
                            <Route path="/expenses/history/list" element={<ExpensesHistoryList/>}/>
                            <Route path="/expenses/categories" element={<ExpensesCategories/>}/>
                            <Route path="/investments" element={<Investments/>}/>
                            <Route path="/settings" element={<Settings/>}/>
                        </Route>
                        <Route path="/login" element={<Login/>}/>
                    </Routes>
                </AuthProvider>
            </main>
        </div>
    );
}