import React from "react";
import NavButton from "./NavButton";


export default function Header({ previousPage, text}) {
    if (previousPage) {
        return (
            <header>
                <div className="App-header">
                    <div className="App-header-button">
                        <NavButton icon="chevron-arrow-left" route={previousPage}/>
                    </div>
                    <div className="App-header-title">
                        <p>{text}</p>
                    </div>
                    <div className="App-header-button">
                        <NavButton icon="settings" route="/settings"/>
                    </div>
                </div>
            </header>
        )
    } else {
        return (
            <header>
                <div className="App-header">
                    <div className="App-header-button">
                    </div>
                    <div className="App-header-title">
                        <p>Self Money</p>
                    </div>
                    <div className="App-header-button">
                        <NavButton icon="settings" route="/settings"/>
                    </div>
                </div>
            </header>
        )
    }
}
