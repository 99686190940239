import React, {useState, useEffect} from 'react';
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {refreshToken} from "../services/authService";
import Loader from "./Loader";
import {StartsEndsCurrentMonth} from '../utils/Date'
import {getConfig} from "../config";

ChartJS.register(ArcElement, Tooltip, Legend);  // Регистрация элементов, необходимых для Pie Chart

const BalancePieChart = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, endDate] = StartsEndsCurrentMonth();
    const { API_URL } = getConfig();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseExpenses = await fetch(
                    `${API_URL}/api/v1/expenses/total?startDate=${startDate}&endDate=${endDate}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseExpenses.status === 401) {
                    refreshToken()
                }
                if (!responseExpenses.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const resultExpenses = await responseExpenses.json();

                const labels = [resultExpenses.expenses.title];
                const values = [resultExpenses.expenses.amount];


                const responseIncomes = await fetch(
                    `${API_URL}/api/v1/incomes/total?startDate=${startDate}&endDate=${endDate}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                    }
                );
                if (responseExpenses.status === 401) {
                    refreshToken()
                }
                if (!responseIncomes.ok) {
                    throw new Error('Ошибка получения данных');
                }
                const resultIncomes = await responseIncomes.json();

                labels.push(resultIncomes.incomes.title);
                values.push(resultIncomes.incomes.amount);

                setData({
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            // backgroundColor: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'],
                            // hoverBackgroundColor: [
                            //     'rgba(255, 99, 132, 1)',
                            //     'rgba(54, 162, 235, 1)',
                            //     'rgba(255, 206, 86, 1)',
                            // ],
                            hoverBorderColor: ['#1F3C75', '#1F3C75', '#1F3C75'],
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.8)',
                                'rgba(54, 162, 235, 0.8)',
                                'rgba(255, 206, 86, 0.8)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 0)',
                                'rgba(54, 162, 235, 0)',
                                'rgba(255, 206, 86, 0)',
                            ],
                            borderWidth: 1,
                        }
                    ]
                });
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [startDate, endDate, API_URL]);

    if (loading) return <Loader/>
    if (error) return <p>Ошибка: {error}</p>;

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
    };

    return <Pie data={data} options={options}/>;
};

export default BalancePieChart;