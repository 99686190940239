import React from 'react';
import {useNavigate} from 'react-router-dom';

const Button = ({text, route, key, value}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(route);
    };

    return (
        <div className={"Button"}>
            <button key={key} value={value} onClick={handleClick}>
                {text}
            </button>
        </div>
    );
};

export default Button;