import React, {createContext, useState, useContext, useEffect} from 'react';

// Создаем контекст
const AuthContext = createContext();

// Хук для доступа к контексту
export const useAuth = () => useContext(AuthContext);

// Провайдер контекста
export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(true);

    // Функция для авторизации
    const login = (newToken) => {
        setIsAuthenticated(true);
        setToken(newToken);
        localStorage.setItem('token', newToken); // сохраняем токен в локальном хранилище
    };

    // Функция для выхода
    const logout = () => {
        setIsAuthenticated(false);
        setToken(null);
        localStorage.removeItem('token');
    };

    // Проверка токена из локального хранилища при монтировании
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setIsAuthenticated(true);
            setToken(storedToken);
        }
        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{isAuthenticated, token, login, logout, loading}}>
            {children}
        </AuthContext.Provider>
    );
};